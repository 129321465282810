const fixSlashes = (str) => str.replace(new RegExp('/{2,}', 'g'), '/');
// fixSlashes removes double slashes, for example, "//login" becomes "/login"
// double slashes can occur when ${currentLang} is an empty string

exports.linkResolver = (doc) => {
  const { type, lang = '', uid = '', slug } = doc;
  // global meaning this is the generic non-language English page

  /* when the page data from Prismic has locale en-nz, consider that
     the default and remove the locale, otherwise pull the language
     from the locale.  This keeps en-nz pages as-is (without the
     language in the URL), and new languages will show in the URL.
     Examples:
     de-de becomes de, so /de/[path]
     pt-br becomes pt, so /pt/[path]
     en-nz becomes (empty string), so /[path]
  */
  const currentLang = lang === 'en-nz' ? '' : lang.replace(/^(\w\w)\-.*/, '$1');

  switch (type) {
    case 'lesmills_plus_landing_page':
    case 'les_mills_plus_tiering_landing_page':
    case 'les_mills_plus_common_landing_page':
    case 'promotion_landing_page': {
      const id = uid === 'index' ? '' : `${uid || slug}/`;
      return `${currentLang}/${id}`;
    }
    case 'affiliate_landing_page':
      return `${currentLang}/offers/`;
    default:
      return '/';
  }
};
